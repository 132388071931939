<script setup lang="ts">
import type { MotionVariants } from '@vueuse/motion'
import 'vue-lite-youtube-embed/style.css'

const props = withDefaults(
	defineProps<{
		title: string
		text?: string
		html?: boolean
	}>(),
	{ html: false },
)

const custom: MotionVariants = {
	initial: { opacity: 0, y: 20 },
	visibleOnce: {
		opacity: 1,
		y: 0,
		transition: {
			type: 'keyframes',
			ease: 'easeOut',
			duration: 500,
			delay: 250,
		},
	},
}
</script>

<template>
	<div class="px-7 sm:px-0 text-center md:text-left">
		<h3
			class="text-[40px] md:text-[44px] xl:text-[50px] font-semibold leading-none tracking-tight"
			v-motion
			v-bind="{
				...custom,
				visibleOnce: {
					...custom.visibleOnce,
					transition: {
						...custom.visibleOnce?.transition,
						delay: 500,
					},
				},
			}"
			v-html="title"
			v-if="title"
		></h3>
		<p v-if="text && !html" class="mt-5" v-motion v-bind="custom">
			{{ text }}
		</p>

		<div
			v-else-if="text && html"
			class="mt-5"
			v-html="text"
			v-motion
			v-bind="custom"
		/>
	</div>
</template>
